import { useCallback } from 'react';

import { enqueueSnackbar, closeSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';

export function successToastFactory() {
  return (message, options) =>
    enqueueSnackbar(message, { variant: 'success', ...options });
}

export function infoToastFactory() {
  return (message, options) =>
    enqueueSnackbar(message, { variant: 'info', ...options });
}

export function warningToastFactory() {
  return (message, options) =>
    enqueueSnackbar(message, { variant: 'warning', ...options });
}

export function errorToastFactory() {
  return (message, options) =>
    enqueueSnackbar(message, { variant: 'error', ...options });
}

export default compose(
  withHandlers({
    toastSuccess: () => successToastFactory(),
    toastError: () => errorToastFactory(),
    toastWarning: () => warningToastFactory(),
    toastInfo: () => infoToastFactory(),
  })
);

export const WithToastProps = {
  toastSuccess: PropTypes.func.isRequired,
  toastError: PropTypes.func.isRequired,
  toastWarning: PropTypes.func.isRequired,
  toastInfo: PropTypes.func.isRequired,
};

export const useToasts = () => {
  const success = useCallback(successToastFactory(), [enqueueSnackbar]);

  const error = useCallback(errorToastFactory(), [enqueueSnackbar]);

  const warning = useCallback(warningToastFactory(), [enqueueSnackbar]);

  const info = useCallback(infoToastFactory(), [enqueueSnackbar]);

  const close = useCallback(infoToastFactory(), [closeSnackbar]);

  return {
    success,
    error,
    warning,
    info,
    close,
  };
};
